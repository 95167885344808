<template>
  <div class="item-description flex-grow">
    <a
      v-if="packageId"
      :href="packagePath"
      class="in-cart-package-link"
      role="button"
    >
      <p class="name flex-grow font-weight-600"> {{ name }}</p>
    </a>
    <p
      v-else
      class="name flex-grow font-weight-600"
    >
      {{ name }}
    </p>

    <div
      v-if="isHotelItem && item && (item?.hotel_reservations?.[0]?.hotel_check_in_date || item?.contract_check_in)"
      class="quote-hotel-check-in-out-dates"
    >
      <span>{{ reformatCheckIn }},</span> <span>{{ reformatCheckOut }}</span>
    </div>

    <div
      v-if="order && order.order_type!='WebOrder'"
      class="quote-products-modal"
    >
      <QuoteProductsModal
        v-show="isModalVisible"
        :product-names="productNames"
        :name="name"
        @close="closeModal"
      />
    </div>
    <p class="price">
      {{ price }}
    </p>
    <p
      v-if="!isHotelItem"
      class="event-name"
    >
      {{ description() }}
    </p>
    <p
      v-if="candelete == &quot;true&quot;"
      class="delete"
      @click="deleteOrderItem"
    />

    <div
      v-if="ers"
      class="errors"
    >
      <span>{{ ers }}</span>
      <span
        class="delete"
        @click="ers = null"
      />
    </div>
  </div>
</template>

<script>
/* global locale */
import Api from '../../cart_api'
import QuoteProductsModal from './q2oo_modal'
import {mapActions, mapMutations} from 'vuex'
import moment from 'moment'

export default {

  components: {
    QuoteProductsModal
  },

  props: {
    candelete: {
      default: '',
      type: String
    },
    eventname: {
      default: null,
      type: String
    },
    itemid: {
      default: 0,
      type: Number
    },
    name: {
      default: null,
      type: String
    },
    item: {
      default: () => {},
      type: Object
    },
    price: {
      default: null,
      type: String
    },
    packageId: {
      default: 0,
      type: Number
    },
    productNames: {
      default: () => [],
      type: Array
    },
    checkin: {
      default: '',
      type: String
    },
    checkout: {
      default: '',
      type: String
    },
    producttype: {
      default: '',
      type: String
    },
    isHotelItem: {
      default: false,
      type: Boolean
    }
  },

  data() {
    return {
      ers: null,
      isModalVisible: false,
      packagePath: `/${locale}/packages/${this.packageId}/path`
    }
  },

  computed: {
    updating(){
      return this.$store.state.order.loading
    },
    order(){
      return this.$store.state.order.order
    },
    reformatCheckIn() {
      const checkIn = this.item?.hotel_reservations?.[0]?.hotel_check_in_date || this.item?.contract_check_in || null

      return checkIn ? `${this.$t('h4h.checkIn')}: ${moment(checkIn, 'MM/DD/YYYY').format('MMMM D, YYYY')}` : ''
    },
    reformatCheckOut(){
      const checkOut = this.item?.hotel_reservations?.[0]?.hotel_check_out_date || this.item?.contract_check_out || null

      return checkOut ? `${this.$t('h4h.checkOut')}: ${moment(checkOut, 'MM/DD/YYYY').format('MMMM D, YYYY')}` : ''
    },
  },

  methods: {
    ...mapActions('order', ['getBackendOrder']),
    ...mapMutations('order', ['updateLoading']),

    deleteOrderItem() {
      if (this.updating) { return }
      this.updateLoading(true)
      this.ers = null
      this.updating = true
      Api.changeOrderItem(this.itemid, 'DELETE', null).then(item => {
        if (item.errors) { this.ers = item.errors }
        this.getBackendOrder().then( () => this.updateLoading(false) )
      })
    },

    closeModal() {
      this.isModalVisible = false
    },
    reformatDates(){
      moment.locale(locale)
      let checkin = moment(this.checkin).format('L')
      let checkout = moment(this.checkout).format('L')
      return `${checkin} - ${checkout}`
    },
    description() {
      switch (this.producttype) {
      case 'ALaCarte':
      case 'Transportation':
        return ''
      case 'Stay':
        if(this.order.in_us){
          return `${this.checkin} - ${this.checkout}`
        } else {
          return this.reformatDates()
        }
      default:
        return this.eventname
      }
    }
  }
}
</script>
