<template>
  <div
    class="form-container login-form "
  >
    <VForm
      id="new_customer"
      class="simple_form second-account-form block"
      novalidate="novalidate"
      action="/customers/sign_in"
      accept-charset="UTF-8"
      data-remote="true"
      method="post"
    >
      <input
        name="utf8"
        type="hidden"
        value="✓"
      >
      <input
        name="authenticity_token"
        type="hidden"
        :value="csrfToken()"
      >
      <div :class="firstEmailVisible ? 'form-group field' : 'form-group field display-none'">
        <div class="form-group field customer-login-container">
          <Field
            id="customer_email_first"
            v-model="loginEmail"
            v-validate="'email'"
            :placeholder="$t('form.email')"
            class="form-control input customer_email_first"
            :class="[updateableEmail ? '' : 'quote-disabled', formDisabled]"
            value=""
            type="email"
            name="customer[email]"
            :rules="validateEmail"
            @keyup.enter="submit"
          />
          <i
            id="emailLogin"
            aria-hidden="true"
            name="commit"
            data-disable-with="submit"
            :class="['fa fa-arrow-right', arrowVisible]"
            @click="showHideForms"
          />
          <a
            :class="[updateableEmail ? 'edit-button' : 'edit-button quote-disabled', editButtonVisible ? 'edit-button' : 'edit-button display-none']"
            role="button"
            @click="resetForm()"
          >
            Edit
          </a>
        </div>
        <ErrorMessage
          as="label"
          name="customer[email]"
          class="error help is-danger is-italic customer-email-first-error"
        />
        <div :class="loginFormVisible ? 'login-form' : 'login-form display-none'">
          <Field
            id="customer_password"
            autocomplete="off"
            :placeholder="$t('form.password')"
            class="form-control password second-account-form-password"
            type="password"
            name="customer[password]"
            :rules="passwordRequirement"
            @keyup.enter="submit"
          />
          <div class="response" />
          <ErrorMessage
            as="label"
            name="customer[password]"
            class="error help is-danger is-italic customer-password-error"
          />
          <div class="form-group field submit">
            <input
              id="loginBtn"
              type="submit"
              name="commit"
              class="btn-block login-btn btn btn-primary"
              :disabled="buttonDisabled"
              data-disable-with="submit"
            >
          </div>
        </div>
      </div>
    </VForm>
    <div :class="forgotPasswordLinkVisible ? 'forgot-password-link' : 'forgot-password-link display-none'">
      <a 
        role="button" 
        href="/customers/password/new"
      >{{ $t('forgotYourPassword') }}
      </a>
    </div>
    <div :class="signupFormVisible ? 'signup-form' : 'signup-form display-none'">
      <VForm
        id="new_customer"
        novalidate="novalidate"
        class="simple_form new_customer new_customer_checkout_form block"
        action="/customers"
        accept-charset="UTF-8"
        data-remote="true"
        method="post"
      >
        <input
          name="utf8"
          type="hidden"
          value="✓"
        >
        <input
          name="authenticity_token"
          type="hidden"
          :value="csrfToken()"
        >
        <div
          v-if="isRoomCash"
          class="form-group"
        >
          <Field
            id="new_customer_first_name"
            :placeholder="$t('form.first_name')"
            class="form-control"
            value=""
            type="text"
            name="customer[first_name]"
            :rules="usernameRequirement"
          />
        </div>
        <ErrorMessage
          as="label"
          name="customer[first_name]"
          class="error help is-danger is-italic new-customer-first-name-error"
        />
        <div
          v-if="isRoomCash"
          class="form-group"
        >
          <Field
            id="new_customer_last_name"
            :placeholder="$t('form.last_name')"
            class="form-control"
            value=""
            type="text"
            name="customer[last_name]"
            rules="required"
          />
          <ErrorMessage
            as="label"
            name="customer[last_name]"
            class="error help is-danger is-italic new-customer-last-name-error"
          />
        </div>
        <div class="form-group field new-customer-email">
          <Field
            id="new_customer_email"
            v-model="loginEmail"
            :placeholder="$t('form.email')"
            class="form-control input"
            :class="[updateableEmail ? '' : 'quote-disabled', formDisabled]"
            value=""
            type="email"
            name="customer[email]"
            :rules="validateEmail"
          />
          <a
            :class="[updateableEmail ? 'edit-button' : 'edit-button quote-disabled', editButtonVisible ? 'edit-button' : 'edit-button display-none']"
            role="button"
            @click="resetForm()"
          >
            Edit
          </a>
        </div>
        
        <div class="form-group field">
          <Field
            id="new_customer_password"
            ref="customer[password]"
            class="form-control input new_customer_password"
            :placeholder="$t('form.password')"
            autocomplete="off"
            type="password"
            name="customer[password]"
            data-vv-as="password"
            data-vv-validate-on="input"
            :rules="validatePassword"
          />
          <div>
            <ErrorMessage
              as="label"
              name="customer[password]"
              class="error help is-danger is-italic new-customer-password-error"
            />
          </div>
          <div class="form-req-holder">
            <em class="form-req">{{ $t('passwordComplexity') }}</em>
          </div>
        </div>
          
        <div class="form-group field">
          <Field
            id="new_customer_password_confirmation"
            class="form-control input new_customer_password_confirmation"
            autocomplete="off"
            :placeholder="$t('signIn.confirmPassword')"
            type="password"
            name="customer[password_confirmation]"
            data-vv-validate-on="input"
            data-vv-as="passwordConfirmation"
            rules="confirmed:@customer[password]"
            @keyup.enter="submit"
          />
          <ErrorMessage
            as="label"
            name="customer[password_confirmation]"
            class="error help is-danger is-italic new-customer-password-confirmation-error"
          />
        </div>
        <div class="form-group field">
          <input
            id="submitBtn"
            type="submit"
            name="commit"
            class=" btn-block create-acct-btn btn btn-primary"
            data-disable-with="Sign up"
            :disabled="buttonDisabled"
          >
        </div>
      </VForm>
    </div>
    <div
      v-if="oauthText && !quoteOrder"
      :class="continueTextVisible ? 'continue-text' : 'continue-text hidden'"
    >
      or continue with
    </div>
    <div class="oauth-block columns is-mobile">
      <form
        v-if="google() && !quoteOrder"
        class="google-oauth-form column control"
        method="post"
        action="/auth/google"
        data-turbo="false"
      >
        <input
          id="login-oauth-google"
          class="oauth-button-google input is-fullwidth"
          type="submit"
          value="GOOGLE"
        >
        <input
          type="hidden"
          name="authenticity_token"
          :value="csrfToken()"
        >
      </form>
      <form
        v-if="microsoft() && !quoteOrder"
        class="microsoft-oauth-form column control"
        method="post"
        action="/auth/microsoft"
        data-turbo="false"
      >
        <input
          id="login-oauth-microsoft"
          class="oauth-button-microsoft input is-fullwidth"
          type="submit"
          value="MICROSOFT"
        >
        <input
          type="hidden"
          name="authenticity_token"
          :value="csrfToken()"
        >
      </form>
      <form
        v-if="apple() && !quoteOrder"
        class="apple-oauth-form column control"
        method="post"
        action="/auth/apple"
        data-turbo="false"
      >
        <input
          id="login-oauth-apple"
          class="oauth-button-apple input is-fullwidth"
          type="submit"
          value="APPLE"
        >
        <input
          type="hidden"
          name="authenticity_token"
          :value="csrfToken()"
        >
      </form>
    </div>
  </div>
</template>

<script>

import { Field, Form as VForm, ErrorMessage } from 'vee-validate'
import { mapMutations } from 'vuex'
import * as yup from 'yup'
import cartApi from '../../shared/cart/cart_api'

export default {
  name: 'DefaultCustomersNewLoginSignup',
  components: {
    VForm,
    Field,
    ErrorMessage
  },

  data() {
    return {
      loginBtnText: '',
      updateableEmail: true,
      loginEmail: '',
      faArrowVisible: true,
      emailFormat: false,
      firstEmailVisible: true,
      loginFormVisible: false,
      signupFormVisible: false,
      forgotPasswordLinkVisible: false,
      oauthButtonsVisible: true,
      continueTextVisible: true,
      signinButtonDisabled: true,
      editButtonVisible: false
    }
  },

  computed: {
    oauthText(){
      if (this.google()) {
        return true
      } else if (this.microsoft()) {
        return true
      } else if (this.apple()) {
        return true
      } else {
        return false
      }
    },

    buttonDisabled(){
      if (this.signinButtonDisabled) {
        return true
      } else {
        return false
      }
    },

    arrowVisible(){
      if (this.faArrowVisible) {
        return 'fadeIn'
      } else {
        return 'hidden'
      }
    },

    // arrowColor(){
    //   if (this.emailFormat) {
    //     return 'white bg-brand-primary'
    //   } else {
    //     return ''
    //   }
    // },

    formDisabled(){
      if (this.editButtonVisible) {
        return 'disabled'
      } else {
        return ''
      }
    },

    isRoomCash() {
      return (window.brand_name == 'RoomCash')
    },

    emailValue: {
      get() {
        return this.$store.state.login.email
      },
      set(email) {
        this.$store.state.login.email = email
      }
    },

    usernameRequirement(){
      if (this.quoteOrder){
        return false // can be false for quote orders
      } else {
        return yup.string().required().min(8)
      }
    },
    passwordRequirement(){
      return yup.string().required().min(8)
    },
    quoteOrder(){
      return document.getElementById('quoteOrder')
    }
  },

  watch: {
    loginEmail() {

      this.updateEmail(this.loginEmail)

      if(this.quoteOrder){return}
      if(this.loginFormVisible || this.signupFormVisible) {
        // this.loginFormVisible = false
        // this.signupFormVisible = false
        this.faArrowVisible = true
        this.forgotPasswordLinkVisible = false
        this.signinButtonDisabled = true
      }
    }
  },

  created(){
    if(this.quoteOrder){
      this.loginEmail = this.quoteOrder.dataset.email
      this.forgotPasswordLinkVisible = false
      this.faArrowVisible = false
      this.updateableEmail = false
      if(this.quoteOrder.dataset.customer) {
        this.loginFormVisible = true
        this.signupFormVisible = false
      } else {

        this.signupFormVisible = true
        this.loginFormVisible = false
      }
    }
  },

  mounted(){
    if(this.quoteOrder) {
      this.showHideForms()
    }
  },


  methods: {
    ...mapMutations('login', ['updateEmail']),

    submit() {
      const login = document.getElementById('emailLogin')
      login.click()
    },

    validateEmail(emailValue){
      if (!emailValue) {
        if(this.isNbaExp())
          return 'this is a required field'
        else
          return 'This field is required'
      }
      // if the field is not a valid email
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i
      if (!regex.test(emailValue)) {
        this.emailFormat = false
        return 'Please enter a valid email'
      } else {
      // All is good
        this.emailFormat = true
        return true
      }
    },

    validatePassword(password){
      if (!password) {
        if(this.isNbaExp())
          return 'this is a required field'
        else
          return 'This field is required'
      }
      // if the field is not a valid email
      const regex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/

      if (!regex.test(password)) {
        return 'Please enter a valid password'
      }
      // All is good
      return true
    },

    showHideForms() {
      const email = this.loginEmail

      cartApi.fetchCustomer(email).then(resp => {
        let customerEmail = document.querySelector('#customer_email_first')
        if(resp.email && email && this.emailFormat) {
          if(!this.quoteOrder) {
            this.loginFormVisible = !this.loginFormVisible
            this.faArrowVisible = !this.faArrowVisible
          }
          if(!this.google() && !this.microsoft() && !this.apple()) {
            this.oauthButtonsVisible = false
            this.continueTextVisible = false
          }
          this.editButtonVisible = true
          this.forgotPasswordLinkVisible = !this.forgotPasswordLinkVisible
          this.signupFormVisible = false
          this.signinButtonDisabled = false
          customerEmail.style.borderRadius = '4px'
        } else if(!resp.email && email && this.emailFormat) {
          if(!this.quoteOrder) {
            this.signupFormVisible = !this.signupFormVisible
          }
          this.faArrowVisible = false
          this.loginFormVisible = false
          this.forgotPasswordLinkVisible = false
          this.firstEmailVisible = false
          this.editButtonVisible = true
          if(!this.google() && !this.microsoft() && !this.apple()) {
            this.oauthButtonsVisible = false
            this.continueTextVisible = false
          }
          this.signinButtonDisabled = false
          customerEmail.style.borderRadius = '4px'
        }
      })
    },

    resetForm(){
      let customerEmail = document.querySelector('#customer_email_first')
      this.firstEmailVisible = true
      this.signupFormVisible = false
      this.faArrowVisible = true
      this.editButtonVisible = false
      this.loginFormVisible = false
      this.forgotPasswordLinkVisible = false
      customerEmail.style.borderRadius = '0'
    },

    csrfToken(){
      let tok = document.getElementsByTagName('meta')['csrf-token']
      if (tok) { // csrf-token is null in testing
        return tok.getAttribute('content')
      } else {
        return null
      }
    },
    google(){
      document.getElementById('google-oauth-login')
      return document.getElementById('google-oauth-login')
    },

    microsoft(){
      document.getElementById('microsoft-oauth-login')
      return document.getElementById('microsoft-oauth-login')
    },

    apple(){
      document.getElementById('apple-oauth-login')
      return document.getElementById('apple-oauth-login')
    },

    isNbaExp() {
      return (window.name == 'nba-exp')
    }
  }
}
</script>
