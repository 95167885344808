<template>
  <div
    class="form-container login-form "
  >
    <div>
      <h3>{{ $t('signIn.login') }}</h3>
      <p>{{ $t('signIn.alreadyMember') }}</p>
    </div>
    <VForm
      id="new_customer"
      class="simple_form second-account-form block"
      novalidate="novalidate"
      action="/customers/sign_in"
      accept-charset="UTF-8"
      data-remote="true"
      method="post"
    >
      <input
        name="utf8"
        type="hidden"
        value="✓"
      >
      <input
        name="authenticity_token"
        type="hidden"
        :value="csrfToken()"
      >
      <div
        class="form-group field"
      >
        <Field
          id="customer_email"
          v-model="emailValue"
          :placeholder="$t('form.email')"
          class="form-control input"
          value=""
          type="email"
          name="customer[email]"
          :rules="usernameRequirement"
        />
        <div>
          <ErrorMessage
            as="label"
            name="customer[email]"
            class="error help is-danger is-italic"
          />
        </div>

        <Field
          id="customer_password"
          autocomplete="off"
          :placeholder="$t('form.password')"
          class="form-control password second-account-form-password"
          type="password"
          name="customer[password]"
          :rules="passwordRequirement"
          @keyup.enter="submit"
        />
        <div class="response" />
        <ErrorMessage
          as="label"
          name="customer[password]"
          class="error help is-danger is-italic"
        />
      </div>
      <div class="form-group field submit">
        <input
          id="loginBtn"
          type="submit"
          name="commit"
          :value="loginBtnText"
          class="btn-block login-btn btn btn-primary"
          data-disable-with="submit"
        >
      </div>
    </VForm>
    <div class="forgot-password-link">
      <a
        role="button"
        href="/customers/password/new"
      >
        {{ $t('forgotYourPassword') }}
      </a>
    </div>
    <div
      v-if="oauthText"
      class="continue-text"
    >
      or continue with
    </div>
    <div class="oauth-block columns is-mobile">
      <form
        v-if="google()"
        class="google-oauth-form column control"
        method="post"
        action="/auth/google"
        data-turbo="false"
      >
        <input
          id="login-oauth-google"
          class="oauth-button-google input is-fullwidth"
          type="submit"
          value="GOOGLE"
        >
        <input
          type="hidden"
          name="authenticity_token"
          :value="csrfToken()"
        >
      </form>
      <form
        v-if="microsoft()"
        class="microsoft-oauth-form column control"
        method="post"
        action="/auth/microsoft"
        data-turbo="false"
      >
        <input
          id="login-oauth-microsoft"
          class="oauth-button-microsoft input is-fullwidth"
          type="submit"
          value="MICROSOFT"
        >
        <input
          type="hidden"
          name="authenticity_token"
          :value="csrfToken()"
        >
      </form>
      <form
        v-if="apple()"
        class="apple-oauth-form column control"
        method="post"
        action="/auth/apple"
        data-turbo="false"
      >
        <input
          id="login-oauth-apple"
          class="oauth-button-apple input is-fullwidth"
          type="submit"
          value="APPLE"
        >
        <input
          type="hidden"
          name="authenticity_token"
          :value="csrfToken()"
        >
      </form>
    </div>
  </div>
</template>

<script>

import { Field, Form as VForm, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'

export default {
  name: 'DefaultCustomersLogin',
  components: {
    VForm,
    Field,
    ErrorMessage
  },

  data() {
    return {
      loginBtnText: ''
    }
  },

  computed: {
    oauthText(){
      if (this.google()) {
        return true
      } else if (this.microsoft()) {
        return true
      } else if (this.apple()) {
        return true
      } else {
        return false
      }
    },

    emailValue: {
      // get() {
      //   return this.$store.state.login.email
      // },
      set(email) {
        this.$store.state.login.email = email
      }
    },

    usernameRequirement(){
      if (this.quoteOrder){
        return false // can be false for quote orders
      } else {
        return yup.string().required().min(8)
      }
    },
    passwordRequirement(){
      return yup.string().required().min(8)
    },
    quoteOrder(){
      return document.getElementById('quoteOrder')
    }
  },

  mounted() {
    this.submitText()
    this.findQuoteEmail()
  },

  methods: {

    submit() {
      const passwordInput = document.getElementById('customer_password')
      const login = document.getElementById('loginBtn')
      passwordInput.addEventListener('keyup', function(e) {
        if (e.keyCode === 13) { login.click() }
      })
    },
    findQuoteEmail(){
      // let quoteOrder =
      if(this.quoteOrder){
        let quoteEmail = this.quoteOrder.dataset.email
        document.getElementById('customer_email').value = quoteEmail
        document.getElementById('customer_email').classList.add('quote-disabled')
      }
    },

    submitText(){
      let path = '/login'
      if(document.getElementById('gaPathOverride')){
        path = document.getElementById('gaPathOverride').dataset.url
      }
      if(path == '/login') {
        this.loginBtnText = this.$t('signIn.login')
      } else {
        this.loginBtnText = 'Log In & Checkout'
      }
    },

    csrfToken(){
      let tok = document.getElementsByTagName('meta')['csrf-token']
      if (tok) { // csrf-token is null in testing
        return tok.getAttribute('content')
      } else {
        return null
      }
    },
    google(){
      document.getElementById('google-oauth-login')
      return document.getElementById('google-oauth-login')
    },

    microsoft(){
      document.getElementById('microsoft-oauth-login')
      return document.getElementById('microsoft-oauth-login')
    },

    apple(){
      document.getElementById('apple-oauth-login')
      return document.getElementById('apple-oauth-login')
    },
  }
}
</script>
