<template>
  <section class="package-item">
    <ItemDescription
      :itemid="item.id"
      :name="item.package_display_name"
      :price="item.package_price_total_decorated"
      :eventname="item.event_display_name"
      :eventlabel="item.event_label"
      :package-id="item.package_id"
      :candelete="cartdisabled ? 'false' : 'true'"
      :cartdisabled="cartdisabled"
      :product-names="item.product_names"
    />

    <div
      v-if="ers"
      class="errors"
    >
      <span>{{ ers }}</span>
      <span
        class="delete"
        @click="ers = null"
      />
    </div>

    <ItemQty
      :item="item"
      :quantity="item.quantity"
      :maxqty="maxPackageQty"
      :updating="updating"
      :candelete="cartdisabled ? 'false' : 'true'"
      :cartdisabled="cartdisabled"
      @update-qty="updateItem"
      @delete-item="deleteItem"
    />

    <a
      v-if="item.hotel_purchasing == true && showhotels!='no_hotels'"
      class="add-hotel-link"
      role="button"
      :href="orderItemAddHotelLink"
    >
      {{ orderItemLinkText }}
    </a>
  </section>
</template>

<script>
import ItemDescription from './item_description'
import ItemQty from '../version_1/item_quantity'
import PackageOnlyJs from '../package_only_item_shared_methods'

export default Object.assign({}, PackageOnlyJs, {
  components: {
    ItemDescription,
    ItemQty
  },
})
</script>
